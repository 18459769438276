input:focus,select:focus{
    outline: none;
}
.loginSubmit div:active , .loginRegister button:active{
    transform: scale(1.01);
}
.container{
    /* width: 100vw; */
    height: 100vh;
    /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
    background:#355239;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Brandlogo{
    position: absolute;
    top: 10px;
    left: 20px;
}
.loginContainer{
    padding: 3rem 2rem;
    border-radius: 18px;
    background: #CFC6B7;    
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    max-width: 25rem;
    min-width: 15rem
}
.registerContainer{
    padding: 3rem 2rem;
    border-radius: 18px;
    background: #CFC6B7;
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;

    max-width: 60rem;

    /* max-width: 25rem;
    min-width: 15rem */
}
.loginInputFields{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.3rem;
}
.loginInputFields input {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 10px;
    text-align: left;
    border: none;
}
.loginInputFields label {
    color: #333431;
    font-size: 0.8em;
    font-weight: 500;
    margin-bottom: 5px;
}
.loginSubmit{
    width: 30%;
    max-width: 15rem;
}
.loginSubmit button{
    gap: 10;
    width: 100%;
    border: none;
    padding: 10px 0px;
    color: #F8D662;
    font-family: Lato;  
    font-size:0.9em;
    border-radius: 5px;
    background: #355239;
    letter-spacing: 1px;
    font-weight: 700;
    cursor: pointer;  
}
.loginRegister{
    width: 100%;
    display: flex;
    justify-content: center;
}
.loginRegister button{
    float: right;
    /* margin-top: 1rem; */
    border-radius: 8px;
    background: #2b5336;
    border: none;
    padding: 7px 15px;
    color: #FFF;
    font-weight: 700;
    cursor: pointer;
}
.loginLogoimg{
    width: 15rem;
    height: 4rem;
    margin-bottom: 2rem;
}
@media (max-width:500px) {
    .loginContainer{
        width: 95%;
    }
}
@media (max-width:900px) {
    .loginContainer{
        width: 45%;
    }
}
.register{
    height: auto;
    /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
    background:#355239;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.front{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    position: relative;
}
.back{
    min-height: 95vh;
    width: 100%;
    padding-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.front p{
    width: 80%;
    line-height: 2rem;
    font-size: 1.1em;
    font-weight: 400;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 70rem;
    color: #F8D662;
}
.front img{
    max-width: 20rem;
}
.downArrow{
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    border-radius: 50px;
    padding: 1px 2px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    animation: downArrow 1s infinite;
}
.downArrow img {
    width: 2.5rem;
}
hr{
    width: 100%;
    margin: 20px 0;
}
@keyframes downArrow {
    0% {
        bottom: 2rem;
    } 
    25% {
        bottom: 1.7rem;
    }
    50% {
        bottom: 1.5rem;
    }
    75% {
        bottom: 1.3rem;
    }
    100% {
        margin-top: 1rem;
    }
}
.selectType{
    display: flex;
    align-items: center;
    width: 100%;
}

.types{
    width: 100%;
    padding: 20px 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    background-color: #F0F3EB;
    cursor: pointer;
    margin-bottom: 2rem;
}
.selected{
    background-color: #ACFF88;
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
    border: 2px solid #ACFF88;
    font-weight: 600;
}
.hide{
    display: none;
}
.groupRegister,.indiRegister{
    width: 100%;
}
.divider{
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(10rem, 1fr));
    gap:20px;
}
.groupRegister{
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(16rem, 1fr));
    gap:20px;
    --grid-column-count: 4;
}
.loginSubmit {
    /* grid-column:span 2; */
}
.checkboxContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.checkboxContainer p{
    font-size: 0.8em;
    font-weight: 400;

    margin: 10px 0;
    margin-left: 1rem;
}
.checkLeft{
    display: flex;
    align-items: center;
}
.checkboxContainer input{
    max-width: 5rem;
}
.loginInputFields select , .loginInputFields  option{
    border-radius: 8px;
    background: #F0F3EB;
    padding: 10px 10px;
    text-align: left;
    border: none;
}
@media only screen and  (max-width:700px) {
    .front p {
        width: 90%;
    }
    
}

@media only screen  and (max-width:400px){
    .selectType {
    width: 120%;
    }
}
@media only screen  and (max-width:600px){
    .selectType {
    width: 110%;
    }
}
.loader{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.loginSubmittt .regbtn{
    width: 10rem;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2b5336;
    color: white;
    text-align: center;
}