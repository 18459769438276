p{
    padding: 0px;
    margin: 0px;
}

html,body{
    /* height: 100vh;
    width: 100vw; */
}
.downArroww{
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    border-radius: 50px;
    padding: 1px 2px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transform: rotate(180deg);
}
.downArroww img {
    width: 2.5rem;
}
/* width */
 ::-webkit-scrollbar {
    width: 7px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(248, 214, 98, 0.40);

    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 33px;
    background: #F8D662;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 33px;
    background: #F8D662;
  }
.containerrr{
   display: flex;
   flex-direction: row;
   background-image: none;
   width: 100%;
   max-height: 100vh;
}
.navContainerr{
    position: relative;
    width: 25%;
    /* height: 100%; */
    max-width: 25rem;
    height: 100%;
    height: 92vh;
    overflow-y: auto;
    /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
    background: #2b5336;
    display: flex;
    flex-direction: column;
    animation-name: open;
    color: white;
    animation-duration: 0.1s;

}
@keyframes open {
    0% {
        width: 0%;
    }
    100% {
        width: 25%;
    }
}
.hiddenNav{
    position: relative;
    width: 5%;
    /* height: 100%; */
    /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
    background: #2b5336;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 100%;
    height: 92vh;
    overflow-y: auto;
}

.displayContainer{
    flex: 1;
    width: 75%;
    height: 100%;
    height: 98vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    padding-bottom: 10px;

}
.hiddenNav img:hover{
    transform: scale(1.1);
    cursor: pointer;
    /* box-shadow: 0 1px 20px white; */
}

.imgConat {
    height: 97%;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* background: green; */
    /* border-bottom: 2px solid #2b5336; */
}
.moduleContainer{

   
    width: auto;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    max-height: 27rem;
    min-height: 27rem;
    padding: 0px 10px;
    padding-right: 5px;
    margin-right: 5px;
    color: black;
}
.modules{
    min-width: 80%;
    max-width: 80%;
    margin: 10px 0px;
    padding:10px 15px;
    /* background: rgba(255, 255, 255, 0.2); */
    /* border-radius: 4px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    /* background-color: white; */
    border-radius: 1px;
    color: #FFF;    ;
    border: 1px solid #CFC6B7;
}
.logo{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 10px;
    /* border-bottom: 1px solid; */
    cursor: pointer;
    flex-direction: column;
}
.logoimg{
    width: 90%;
    height: 3rem;
    max-width: 15rem;
    margin: 0px 10px; 
    /* align-self: flex-start; */
}
.modules:hover{
    /* transform: scale(1.008); */
    zoom: 101%;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    cursor: pointer;
}
.individualNotes{
    border-radius: 1px;
    background: rgba(207, 198, 183, 0.40);
    display: flex;
    align-items: center;
}
.title{
    padding: 0px;
    margin: 10px 10px;
    color: #FFF;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 1em;
    letter-spacing: 1px;
    /* transform: scale(1.1, 1.5); */
    }
.chatContainer{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    color: black;
}
.sessionsContainer{
    width: 100%;
    height: 20%;
    min-height: 4rem;
    max-height: 8rem;
    padding-top: 3rem;
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center; 
    scrollbar-width: 7px;
}
.tasksContainer{
    /* height: 80%; */
    border-radius: 0px 0px 20px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: center;
    padding: 10px 20px;
    overflow-y: scroll;
    min-height: 20rem;
    max-height: 100%;
    margin-top: 10px;
}
.mainContainer{
    width: 100%;
    /* height: 95%; */
    /* border-radius: 20px; */
    /* height: "100%", height:"98vh",overflowY:"auto" */
    box-shadow: 0 4px 4px #00000033;
    flex-direction: column;
    display: flex;
    /* margin-top: 3rem; */
    height:100%;
    max-height: 90vh;
    overflow-y: auto;

}
.nav-btn{
    padding: 10px 60px;
    border-radius: 114px;
    border: 1px solid #CFC6B7;
    cursor: pointer;
}
.navigation
{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 95%;
    align-self: center;
    z-index: 100;
    margin: 20px 0;
    /* position: fixed; */
    bottom: 1px;
    background-color: white;
}
.nav-btn:active{
    transform: scale(1.01);
    font-weight: 500;
    /* border: 1.3px solid black; */
}
.closeBar{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.closeBar:hover{
    transform: scale(1.09);
}
.moduleHeader{
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.horiScroll{
    display: flex;
    overflow-x: hidden;
    flex: 1;
    min-height: 100%;
    border-bottom: 1px solid #CFC6B7 ;
    
}
.session{
    padding: 10px 20px;
    /* border-radius: 10px;
    background-color: #fdd447;
    margin: 0 20px; */
    min-width: 10rem;
    min-height: 3rem;
    max-height: 3rem;
    max-width: 20rem;   
    flex: 1;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom:2px solid #2b5336; */
}
.highlight{
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px 10px 0px 0px;
    background: #F7DBFF;    /* border: 2px solid #2b5336; */
    border-radius: 10px 10px 0 0; 
    border-bottom: none;
}
.selectedModule{
    /* font-weight: 600; */
    border-radius: 1px !important;
    border: 1px solid #CFC6B7 !important;
    background: #CFC6B7 !important;
    color: black;
}
.disabled{
    cursor: not-allowed !important;
    opacity: 0.5;
}
.selected{
    font-weight: 500;
}
.addModule{
    display: flex;
    justify-content: end;
    padding: 10px 0px;
    width: 90%;
    /* margin-top: 4rem; */
}
.addModule button
{
    padding:10px;
    background-color: #2b5336;
    color: #ffffff;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
}
.addModule button:active{
    transform: scale(1.0);
}
.chatModule{
    height: 100%;
    width: 100%;
    border-top: 2px solid #d9d9d9;
}
.indNotesModule,.grpObservation{
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.modulesGrid,.sessionModule{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(11rem,1fr));
    gap: 15px 10px;
    width: 100%;

}
.individualModule{
    padding: 10px;
    align-items: center;
    border-radius: 94px;
    border: 1px solid #CFC6B7;
    text-align: center;
    display: flex;
    min-width: 10rem;
    /* max-width: 15rem; */
    text-wrap: wrap;
    justify-content: center;
    font-size: 0.9em;
    color: #252422;
    font-family: Lato;
    cursor: pointer;
}
.chatHeader{
    margin: 20px 0;
    font-weight: 600;
}
.chatModule{
    width: 100%;
    height: 91vh;
    overflow-y: auto;}
.individualModule:hover{
    transform: scale(1.01);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.individualModule:active{
    transform: scale(1.03);
}

.indiChat{
    display: flex;
    flex-direction: column;
    width: 90%;
   border-bottom: 1.2px solid #CFC6B7;;
    margin: 3rem 0;
    margin-left: 5%;
    border-radius: 1px;
}
.sessionName{
    width: 30% ;
    border-radius: 1px;
    padding: 10px;
    min-width: 4rem;
    border: 1.2px solid #CFC6B7;;
    border-right: 0px;
    border-bottom: 0px;
    font-size: 0.9em;
    display: flex;
    justify-content: center;
    font-weight: 400 !important;
    align-items: center;
}
.Learnings{
    border-radius: 1px;
    width: 100%;
    padding: 10px;
    border: 1.2px solid #CFC6B7;;
    border-bottom: 0px;
    font-size: 0.9em;
    text-align: left;
    font-weight: 400 !important;

}
.chat{
    display: list-item;
    margin-left: 2rem;
    padding: 5px 0;
}
.header{
    text-align: center;
    font-weight: 500;
    font-size: 1em;
}
.header , .body{
    display: flex;
    flex-direction: row;
    text-align: center;
}
.moduleSelection{
    width: 80%;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    max-width: 50rem;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 2rem;
}
.moduleOption{
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 94px;
    border: 1.2px solid #355239;
    max-width: 50rem;
    margin-top: 1rem;
}
.grpObservationChat{
    width: 90%;
    min-height: 10rem;
    margin-top: 1rem;
    background-color:#f1f1f1;
    /* max-width: 50rem; */
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 1rem;
    border-radius: 5px;
    align-self: center;
    max-height: 20rem;
    overflow-y: auto;
}
.chatConatiner{
    padding: 20px;
    display: flex;
    flex-direction: column;

}
.chatName{
    color: #2b5336;
    font-size: 1em;
    font-weight: 600;
    margin-right: 20px;
 
}
.chatDesc{
    font-size: 0.9em;

}
.chatDate{
    font-size: 0.6em;
    color: black;
    display: flex;
    align-items: end;
    height: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    
}
.sessionNameContainer{
    font-weight: 500;
    color: #000;
}
.addChatContainer{
    display: flex;
    width: 80%;
    justify-content: space-between;
}
.addChatBtn{
    padding: 10px 60px;
    border-radius: 1px;
    border: 1px solid #CFC6B7;
    cursor: pointer;
    color: #1A291D;
    font-family: Lato;
    margin-bottom: 1rem;
}
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 20px;
    height: 20rem;
    min-width: 35rem;
    min-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.55);
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 101;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .indiContainer , .grpContainer{
    width: 100%;
    height: 100%;
    display: flex   ;
    flex-direction: column;
    justify-content: space-between;
  }
  .closeModel{
    align-self: flex-end;
  }
  .closeModel img{
    width: 1rem;
    height: auto;
    cursor: pointer;
  }
  
  .closeModel img:hover{
    transform: scale(1.3);
  }
  .indiContainer textarea , .grpContainer textarea{
    width: 95% !important;
    height: 100% !important;
    outline: none;
    font-size: 1.1em;
    resize: none;
  }
@media screen and (max-width:700px) {
    .mainContainer {
        width: 95%;
        height: 95%;
    }
    .hiddenNav{
        padding: 0 3px;
    }
    .modules {
        min-width: 80%;
        max-width: 90%;
        margin: 10px 0px;
        padding: 10px 10px;
    }
    .mymodal{
        min-width: 28rem;
    }
}
.dashboardHeader{
    min-height: 3rem;
    max-height: 3rem;
    background-color: #2b5336;
    width: 100%;
    /* margin-bottom: 20px; */
    width: -webkit-fill-available;
    /* position: fixed; */
    display: flex;
    padding-bottom: 2px;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
    z-index: 10;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.10);}
.dashboardHeader img {
    cursor: pointer;
}
.profileBar{
    height: 8rem;
    width: 15rem;
    position: absolute;
    right: 1rem;
    top: 3rem;
    display: flex;
    z-index: 10;
    flex-direction: column;
    justify-content: space-around;
    background-color: #2b5336;
    /* align-items: center; */
}
.profileBar div {
    padding: 20px 10px;
    color: white;
    font-size: 1em;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
}
.profileBar div:hover{
   background-color: #346341;
}
.fullpageloader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    z-index: 9999;
    width: 100vw;
    opacity: 50%;
    height: 100vh;
  }
  .taskTitles{
    font-weight: 500;
    font-size: 1.1em;
    padding: 10px 0px;
    margin-bottom: 10px;
  }
  .taskDesc a{

  }
  .downloadBtn{
    width: 25px;
    margin-right: 10px;
  }
  .clicktoDownload{
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
    color: #000;
    width: 20%;
    font-family: Lato;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    text-decoration-line: underline;
  }
  .taskDescc{
    width: 100%;
  }
  .taskAssignment{
    display: flex;
    min-width: 100%;
    /* justify-content: space-between; */
    flex-direction: column;
  }
  .assignment{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .assignment label{
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .assignment input{
    border-radius: 1px;
    border: 2px dashed #F7DBFF;
    max-width: 11rem;
    background-color: white;
    padding: 10px 20px;

  }
  .taskCompletion{
    align-self: center;
    padding: 10px 20px;
    border-radius: 1px;
    border: 1px solid #71E454;
    cursor: pointer;
    margin-top: 2rem;
    color: #71E454;
    align-self: flex-end;
    display: flex;
    align-items: center;
}
  .taskCompletion:active{
    transform: scale(1.01);
  }
  .uploadDiv{
    position: relative;
    display: flex;
    border-radius: 1px;
    border: 2px dashed #F7DBFF;
    max-width: 11rem;
    background-color: white;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 10;
    justify-content: center;
    font-family: Lato;
    align-items: center;
  }
  .uploadInput{
    opacity: 0;
    position:absolute;
    margin: 0;
    top: 0;
  }
  .test{
    max-height: 92vh;
    overflow-y:auto ;
    padding-left: 1rem;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    width: 97%;
  }
  .selectedModulee{
    background-color: #F8D662;
    color: #1A291D;
  }
  .selectedIndiChat{
    border-radius: 94px;
    border: 1px solid #CFC6B7;
    background: #CFC6B7;
  }
  .myLearnings{
    font-size: 1.1em;
    margin-top: 3rem;
    font-weight: 600;
  }
  .indiSubmit button{
    border-radius: 1px;
    background: #CFC6B7;
    padding: 10px 40px;
    color: #252422;
    outline: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
  .indiCancel button{
    border-radius: 1px;
    border: 1px solid #CFC6B7;
    padding: 10px 40px;
    /* background-color: white; */
    color: black;
    outline: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
  .chatBtns{
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-self: center;
  }