.containerr{
    display: flex;
    flex-direction: row;
    background-image: none;
    width: 100%;
    min-height: 92vh;
 }
 .navContainer{
     position: relative;
     width: 25%;
     /* height: 100%; */
     max-width: 25rem;
     /* min-height: 100vh; */
    height: 92vh;
    overflow-y: auto;
     /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
     background: #2b5336;
     display: flex;
     flex-direction: column;
     animation-name: open;
     color: white;
     animation-duration: 0.1s;
 
 }
 @keyframes open {
     0% {
         width: 0%;
     }
     100% {
         width: 25%;
     }
 }
 .hiddenNav{
     position: relative;
     width: 5%;
     /* height: 100%; */
     /* background-image: linear-gradient(90deg, #D6FFDE, #ACFF88); */
     background: #2b5336;
     display: flex;
     flex-direction: column;
     align-items: center;
     color: white;
 }
 
 .displayContainer{
     flex: 1;
     width: 75%;
     height: 100%;
     height: 90vh;
     overflow-y: auto;
     display: flex;
     align-items: center;
     /* justify-content: center; */
     flex-direction: column;
     margin-right: 3px;
 
 }
 .module {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    
 }
 .StepperContainer-1-2-1{
    width: 100%;
 }
 .stepBody{
    min-height: 20rem;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content:center;
    max-width: 65rem;
    position: relative;
 }
 .moduleContainer{
    display: flex;

    justify-content: center;
    margin-top: 1rem;
    align-items: flex-start;
    padding-left: 7%;
 }
 .moduleContainer label
 {
    padding: 20px 0;
    font-weight: 400;
 }
 .moduleContainer input {

    padding: 10px 10px;
    text-align: left;
    border: none;
    min-width: 40%;
    max-width: 30rem;
    border-radius: 1px;
    background: rgba(207, 198, 183, 0.30);
}
.moduleContainer textarea{
    width: 90% !important;
    height: 100% !important;
    outline: none;
    resize: none;
    font-size: 1.1em;
    margin-bottom: 1rem;
    flex: 1;
    border: 1.2px solid #CFC6B7;
}
.indiSession{
    display: flex;
    align-items: center;
    width: 100%;
}
.sesName{
    width: 100%;
    /* margin: 10px 0; */
    display: flex;
}
.sessionNameIndi{
    width: 100%;

    min-width: 90% !important;
    max-width: 40rem !important;
    margin-left: 2rem;
}
.addbutton 
{
    transform: rotate(45deg);
    width: 1rem;
    height: auto;

    
}
.removebutton{
    width: 1rem;
    height: auto;
}
.addContainer{
    align-self: center;
    padding: 15px;
    border-radius: 20px;
    /* background-color: #F0F3EB; */
    cursor: pointer;
}
.glass{
    background: rgba( 255, 255, 255, 0);
    backdrop-filter: blur(50px);
    position: absolute;
    top: 23rem;
    right: 1rem;
/*Adding shadow to the content*/
    box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 );
}
.taskInput{
    border-radius: 8px;
    background: #F0F3EB;
    padding: 10px 10px;
    text-align: left;
    border: none;
}
.taskConatainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    /* margin-right: 3rem; */
}
.taskDesc{
    width: 100%;
    min-height: 10rem;
}
.taskDesc input {
    /* width: 90%;
    max-width: 100rem;
    height: 30%; */
    background: none;
}
.inputDiv{
    min-height: 10rem;
    height: 10rem;
    width: 90%;
    border-radius: 1px;
    border: 1.2px solid #CFC6B7;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uplodedFile{
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginSubmittt button{
    border-radius: 1px;
    border: 1px solid #CFC6B7;
    background: #CFC6B7;
    padding: 10px 10px;

}
.innerInputDiv{
    border: 1.2px dashed #CFC6B7;
    background: rgba(207, 198, 183, 0.25);
    flex: 1;
    display: flex;
    align-items: center;
    margin: 10px;
    height: 90%;
    font-size: 0.8em;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
}

.chooseYourFile{
    position: relative;
    /* background-color: red; */
}
.fileUploder{
    width: 100%;
    cursor: pointer;
}
.createAccount{
    width: 100%;
    height: 100%;
}
.accountHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    /* padding-left: 0; */
}
.innerInputDiv p{
    margin: 10px;
}
.align{
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    padding: 10px 20px;
}
.accountHeader button{
    padding: 10px;
    background-color: #2b5336;
    color: white;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1em;
    outline: none;
    cursor: pointer;
    border: none;
}
.accountHeader button:active{
    transform: scale(1.02);
}
.accountHeader label{
    font-weight: 700;
    font-size: 1.1em;
}
.accountBody{
    padding: 2rem 2rem;

}
.createNewAccount{
    width: 85%;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
}
.newAccount{
    display: flex;
    flex-direction: column;
    width: 45%;
    padding-bottom: 1rem;

}
.newCreateAccount{
    display: flex;
    flex-direction:column;
    /* align-items: center; */
    margin-left: 3%;

}
.newAccount label{
    font-size: 0.9em;
    font-weight: 500;
    padding: 10px 0;
}
.newAccount input,.newAccount select{

    padding: 10px 10px;
    text-align: left;
    border: none;
    border-radius: 1px;
    background: rgba(207, 198, 183, 0.30);
}
.createBtn {
    align-self: center;
    width: 40%;
    max-width: 25rem;
    margin-top: 3rem;
}
.createBtn button{
    padding: 10px;
    align-self: center;
    color: white;
    background-color: #2b5336;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;
    cursor: pointer;
}
.createBtn button:active{
    transform: scale(1.01);
}
.popupBtn{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popupConatiner{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 70%;
}
.popupConatiner label{
    font-weight: 600;
    font-size: 1em;
}
.newAccount button{
    padding: 2px;
    background-color: #F0F3EB;
    border: none;
    margin-top: 10px;
    border: 1px solid black;
    border-radius: 5px;
}
.programList{
    display: flex;
}
.listt{
    width: 3rem;
    padding: 5px 0;
}
.list{
    width: 25rem;
    padding: 5px 0;
}
.header{
    font-weight: 600;
}
@media (max-width:1500px) {
.moduleContainer{
    min-height: 25rem;
    max-height: 25rem;
}
    
}
.programmeList{
    display: flex;
    margin-bottom: 1.5rem;
    /* align-items: center; */
    flex-direction: column;
}
.programmeList select{
    min-width: 10rem;
    max-width: 50rem;
    /* margin-left: 5rem; */
    margin-top: 1rem;
    width: 90%;
    height: 2rem;
    border-radius: 5px;
    background-color: white;
}
.programmeList label{
    min-width: 10rem;
    font-weight: 500;
}
.taskHeaderRow{
    display: flex;

}
.taskHeader{
    width: 10rem;
    border: 1px solid black;
    text-align: center;
    padding: 10px 20px;
}
.taskBodyRow{
    display: flex;
}
.taskBody{
    width: 10rem;
    border: 1px solid black;
    text-align: center;
    padding: 10px 20px;
}
.download{
    padding: 10px 10px;
    color: #F0F3EB;
    background-color: #2b5336;
    border-radius: 5px;
    width: 10rem;
margin-left: 25%;    font-weight: 600;
    text-align: center;
cursor: pointer;
text-decoration: none;
display: list-item;
}
.moduleOptionss{
    width: 100%;
    padding: 10px;
    border-radius: 1px;
    background: rgba(207, 198, 183, 0.60);
    max-width: 50rem;
    /* margin-top: 1rem; */
    border: none;
}
.imp{
    min-width: 40% !important;
}
@media screen and (max-width:900px) {
    .taskConatainer{
        flex-direction:column;
        align-items: flex-start;
        
    }
    .nav-btn{
        padding: 10px 40px;
    }
    .newAccountMobile{
        flex-direction:column;
        align-items: flex-start;
    }
    .newAccount {
        width: 100%;
    }
}
.downloadPdf{
    float: right;
    width: 6rem;
    padding: 10px 20px;
    border-radius: 1px;
    border: 1px solid #CFC6B7;
    text-align: center;
    cursor: pointer;
}
.radio{
    color:gray;
}
.sort{
    width: 100%;
    display:flex;
    justify-content: space-between;
}
.sort select{
    padding: 10px 20px;
    border-radius: 94px;
    border: 1.2px solid #355239;
}
.deleteConfirm{
    padding: 10px 20px;
    background-color: #355239;
    color: #F0F3EB;
    border-radius: 12px;
    width: 4rem;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}
.deleteConfirm:active{
    transform: scale(1.01);
}
.backBtn{
    position: absolute;
    width: 30px;
    margin: 2rem;
    cursor: pointer;
}
.moduleSelector{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.moduleCol{
    display: flex;
    flex-direction: column;
}