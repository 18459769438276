.profileContainer{
    margin-top: 4rem;
    width: 90%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
}
 input{

    padding: 10px 10px;
    text-align: left;
    border: none;
    /* width: 60%; */
    max-width: 45rem;
    margin-top: 10px;
    border-radius: 1px;
    background: rgba(207, 198, 183, 0.30);
}
.profileHeader{
    display: flex;
    align-items: center;
}
.profileHeader img {
    cursor: pointer;
}
 .profileHeader label{
    margin-left: 20px;
    font-size: 1.2em;
    font-weight: 600;
}
.profileInputs{
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-top: 1.5rem;
    width: 45%;
}
.profileInputs label{
    font-size: 0.9em;
}
.profileUpdateBtn{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
.profileUpdateBtn button{
    border-radius: 114px;
    border: 1px solid #355239;
    background: #355239;
    gap: 10;
    width: 10rem;
    border: none;
    padding: 10px 0px;
    color: #FFF;
    font-size: 0.9em;
    /* font-family: Andika; */
    /* font-style: normal; */
    font-weight: 500;
    /* line-height: normal; */
    cursor: pointer;  
}
.profileUpdateBtn button:active{
    transform: scale(1.02);
}
.profileAlign{
    display: flex;
    width: 90%;
    justify-content: space-between;
}