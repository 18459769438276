.editContainer{
    width: 100%;
    height: 100%;
}
.editHeader{
    display: flex;
    align-items: center;
    padding: 20px;
}
.editHeader img {
    cursor: pointer;
}
.editHeader label{
    margin-left: 20px;
    font-size: 1.2em;
    font-weight: 600;
}
.editBody{
    padding: 20px;
    width: 45%;
}
.editinput{
    display: flex;
    flex-direction: column;
}
.editinput input{
    border-radius: 8px;
    background: #F0F3EB;
    padding: 10px 10px;
    text-align: left;
    border: none;
    /* width: 60%; */
    max-width: 45rem;
    margin-top: 10px;
}
.editinput select{
    border-radius: 8px;
    background: #F0F3EB;
    padding: 10px 10px;
    text-align: left;
    border: none;
    width: 100%;
    max-width: 45rem;
    margin-top: 10px;
}
.editUpdate{
    align-self: center;
    justify-content: center;
    display: flex;
    margin-top: 1rem;
}
.editUpdate button{
    max-width: 20rem;
    padding: 10px;
    border-radius: 8px;
    background: #2b5336;
    color: white;
    border: none;
    width: 20%;
    cursor: pointer;
    font-size: 1em;
}
.editUpdate button:active{
    transform: scale(1.01);
}
@media screen and (max-width:900px) {
    .usermobile
    {
        flex-direction: column;
    }
    .editBody {
        width: 80%;
    }
}